/* Animations */

:root {
  --duration-short: 100ms;
  --duration-default: 200ms;
  --duration-announcement-bar: 250ms;
  --duration-medium: 300ms;
  --duration-long: 500ms;
  --duration-extra-long: 600ms;
  --duration-extended: 3s;
  --ease-out-slow: cubic-bezier(0, 0, 0.3, 1);
  --animation-slide-in: slideIn var(--duration-extra-long) var(--ease-out-slow) forwards;
  --animation-fade-in: fadeIn var(--duration-extra-long) var(--ease-out-slow);
}

@media (prefers-reduced-motion: no-preference) {
  .animate--ambient > img,
  .animate--ambient > svg {
    animation: animateAmbient 30s linear infinite;
  }

  @keyframes animateAmbient {
    0% {
      transform: rotate(0deg) translateX(1em) rotate(0deg) scale(1.2);
    }
    100% {
      transform: rotate(360deg) translateX(1em) rotate(-360deg) scale(1.2);
    }
  }

  .scroll-trigger.animate--fade-in,
  .scroll-trigger.animate--slide-in {
    opacity: 0.01;
  }

  .scroll-trigger.animate--slide-in {
    transform: translateY(2rem);
  }

  .scroll-trigger:not(.scroll-trigger--offscreen).animate--fade-in {
    opacity: 1;
    animation: var(--animation-fade-in);
  }

  .scroll-trigger:not(.scroll-trigger--offscreen).animate--slide-in {
    animation: var(--animation-slide-in);
    animation-delay: calc(var(--animation-order) * 100ms);
  }

  .scroll-trigger.scroll-trigger--design-mode.animate--fade-in,
  .scroll-trigger.scroll-trigger--design-mode.animate--slide-in,
  .scroll-trigger.scroll-trigger--design-mode .slider,
  .scroll-trigger:not(.scroll-trigger--offscreen).scroll-trigger--cancel {
    opacity: 1;
    animation: none;
    transition: none;
  }

  .scroll-trigger.scroll-trigger--design-mode.animate--slide-in {
    transform: translateY(0);
  }

  @keyframes slideIn {
    from {
      transform: translateY(2rem);
      opacity: 0.01;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0.01;
    }
    to {
      opacity: 1;
    }
  }
}